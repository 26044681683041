import {CustomCssVarsFnParams} from '../../../baseCustomCssVars';

/* istanbul ignore file */
export const sliderCustomCssVars = ({styleParams: styles}: CustomCssVarsFnParams) => {
  const productHasVisibleOutline = () => {
    const hasVisibleBorder =
      styles.strings.gallery_borderWidth?.value && styles.strings.gallery_borderWidth.value !== '0';
    if (hasVisibleBorder) {
      return true;
    }

    const rgbaProductBackgroundAlphaValue = styles.colors.gallery_productBackground?.match(/[.?\d]+/g)[3] ?? '0';
    return rgbaProductBackgroundAlphaValue !== '0';
  };

  return {
    sliderArrowsReferenceHeight: productHasVisibleOutline() ? '100%' : 'unset',
  };
};
